import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Articles from "../components/articles"
import Banner from "../components/banner";

const LifePage = ({ data }) => {
  return (
    <Layout>
      <SEO title="ライフ" keywords={[`ライフ`, `働き方`, `リモートデスクトップ`, `時短`]} />
      <Articles title="ライフ一覧" data={data.articles}/>
      <Banner/>
    </Layout>
  );
}

export default LifePage;

export const query = graphql`
query {
    articles: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {
        fields: { 
          reserved: { eq: false },
          attributes: { in: ["life"] }
        }
      }) {
      totalCount
      edges {
        node {
          id
          fields {
            date(formatString: "YYYY年M月D日")
            author
            thumbnail {
              childImageSharp {
                fixed(width: 304, height: 240, cropFocus: CENTER, quality: 90) {
                  width
                  height
                  src
                  srcSet
                }
              }
            }
            title
            slug
            attributes
            summary
            tags
           
          }
          excerpt
        }
      }
    }  
  } 
`
